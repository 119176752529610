
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import FilterFrom from "./FilterForm.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FilterFrom,
  },
  data() {
    return {
      Orders: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchOrders: null,
      OrdersSearchMode: false,
      searchModel: "",
      state: state,
      Workers: [],
      customers: [],
    };
  },
  methods: {
    handleFormSubmitted(formData) {
      // Handle the form data here
      console.log("Form data received:", formData);
      this.http.post("orders/search", formData).then((response) => {
        console.log(response.data);
      });
    },

    getWorkers() {
      let userData = JSON.parse(localStorage.currentUser);
      this.http.get("workers/" + userData.branch_id).then((res) => {
        this.Workers = res.data;
      });
    },
    getCustomers() {
      let userData = JSON.parse(localStorage.currentUser);
      this.http.get("customers/" + userData.branch_id).then((res) => {
        this.customers = res.data;
      });
    },
    addOrders() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          // { model: "office_id", type: "text", label: "orders.office_id" },
          {
            model: "recrucment_visa",
            type: "text",
            label: "orders.recrucment_visa",
          },

          {
            model: "cutomer_id",
            type: "select",
            options: (() => {
              return this.customers.map((data) => {
                return { value: data.id, label: data?.name };
              });
            })(),
            label: "popups.cutomer_id",
          },

          {
            model: "worker_id",
            type: "select",
            options: (() => {
              return this.Workers.map((data) => {
                return { value: data.id, label: data?.name };
              });
            })(),
            label: "popups.worker_id",
          },
          {
            model: "worker_passport",
            type: "text",
            label: "orders.worker_passport",
          },
          {
            model: "contract_date",
            type: "date",
            label: "orders.contract_date",
          },
          // { model: "progress", type: "text", label: "orders.progress" },
          // { model: "is_done", type: "text", label: "orders.is_done" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              let userData = JSON.parse(localStorage.currentUser);
              obj.office_id = userData.branch_id;
              obj.progress = 0;

              this.http.post("orders", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editOrders(app) {
      var data = {
        title: "popups.orders",
        inputs: [
          {
            model: "recrucment_visa",
            type: "text",
            label: "orders.recrucment_visa",
            value: app.recrucment_visa,
          },

          {
            model: "cutomer_id",
            type: "select",
            options: (() => {
              return this.customers.map((data) => {
                return { value: data.id, label: data?.name };
              });
            })(),
            label: "popups.cutomer_id",
          },

          {
            model: "worker_id",
            type: "select",
            options: (() => {
              return this.Workers.map((data) => {
                return { value: data.id, label: data?.name };
              });
            })(),
            label: "popups.worker_id",
          },
          {
            model: "worker_passport",
            type: "text",
            label: "orders.worker_passport",
            value: app.worker_passport,
          },

          {
            model: "contract_date",
            type: "date",
            label: "orders.contract_date",
            value: app.contract_date,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("orders", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateOrders(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "office_id", type: "text", label: "orders.office_id" },
          {
            model: "recrucment_visa",
            type: "text",
            label: "orders.recrucment_visa",
          },
          { model: "cutomer_id", type: "text", label: "orders.cutomer_id" },
          {
            model: "worker_passport",
            type: "text",
            label: "orders.worker_passport",
          },
          { model: "status_id", type: "text", label: "orders.status_id" },
          {
            model: "contract_date",
            type: "text",
            label: "orders.contract_date",
          },
          { model: "progress", type: "text", label: "orders.progress" },
          { model: "is_done", type: "text", label: "orders.is_done" },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("orders", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.OrdersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("orders/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Orders = res.data;
          console.log("######## files", this.Orders);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.OrdersSearchMode = false;
      this.get(this.page);
    },

    getOrders() {
      this.http.get("orders").then((res) => {
        this.Orders = res.data;
      });
    },
    deleteOrders(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("orders", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("orders/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Orders = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getWorkers();
    this.getCustomers();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.orders.text')"
    />
    <div class="row">
      <FilterFrom @form-submitted="handleFormSubmitted" />

      <div class="col-12" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="OrdersSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addOrders()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("orders.id") }}</th>
          <th scope="col">{{ $t("orders.office_id") }}</th>
          <th scope="col">{{ $t("orders.recrucment_visa") }}</th>
          <th scope="col">{{ $t("orders.cutomer_id") }}</th>
          <th scope="col">{{ $t("orders.worker_passport") }}</th>
          <th scope="col">{{ $t("orders.contract_date") }}</th>
          <th scope="col">{{ $t("orders.progress") }}</th>
          <th scope="col">{{ $t("orders.is_done") }}</th>
          <th scope="col">{{ $t("orders.created") }}</th>
          <th scope="col">{{ $t("orders.updated") }}</th>
          <th scope="col">{{ $t("orders.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Orders" :key="app" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ app.office?.name }}</td>
          <td>{{ app.recrucment_visa }}</td>
          <td>{{ app.cutomer.name }}</td>
          <td>{{ app.worker_passport }}</td>
          <td>{{ app.contract_date }}</td>
          <td>{{ app.progress }}</td>
          <td v-if="app?.is_done == null">Not Finished</td>
          <td v-if="app?.is_done != null">Finished</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td colspan="2">
            <router-link
              class="btn btn-success mx-1"
              :to="'/customer-files/' + app.worker_id"
              href="javascript: void(0);"
              role="button"
              >{{ $t("menu.menuitems.documents.text") }}</router-link
            >
            <a
              class="btn btn-primary mx-1"
              @click="editOrders(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteOrders(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




