<template>
  <div class="container mt-3">
    <div class="card">
      <div class="card-header bg-dark">
        <div class="card-title">
          <h4 style="color: #ffffff !important">
            {{ $t("filter.filter_form_title") }}
          </h4>
        </div>
      </div>
      <form @submit.prevent="submitForm">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-3">
              <label for="orderId">{{ $t("filter.filter.order_id") }}</label>
              <input
                type="text"
                class="form-control"
                id="orderId"
                v-model="formData.customer_name"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="donatorId">{{
                $t("filter.filter.order_id_placeholder")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="donatorId"
                v-model="formData.visa"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="donatorName">{{
                $t("filter.filter.donator_id")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="donatorName"
                v-model="formData.passport"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12 text-right">
            <button type="submit" class="btn btn-primary mx-1">
              {{ $t("filter.filter.submit") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary mx-1"
              @click="resetForm"
            >
              {{ $t("filter.filter.reset") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        customer_name: null,
        visa: null,
        passport:null,
      },
    };
  },
  methods: {
    submitForm() {
      this.$emit("form-submitted", this.formData);
    },
    resetForm() {
      this.formData = {
        customer_name:null,
        visa: null,
        passport: null,
      };
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
}
.form-row {
  margin-bottom: 1rem;
}
.text-right {
  text-align: right;
}
</style>
